import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Dropdown from './Dropdown';
import imgLogo from '../../assets/logo-zanaflex.png';
import BRLogo from '../../assets/bandeira-brasil.png';
import ESLogo from '../../assets/bandeira-espanha.png';
import USLogo from '../../assets/bandeira-eua.png';
import { Nav, NavMenu, MenuIcon, NavItem, Container, SelectLanguage, Section } from './styles';
import {
  infraestruturaMenuItems,
  infraestruturaESMenuItems,
  infraestruturaUSMenuItems,
  qualidadeMenuItems,
  qualidadeESMenuItems,
  qualidadeUSMenuItems,
  logisticaMenuItems,
  logisticaESMenuItems,
  logisticaUSMenuItems,
  produtosMenuItems,
  produtosESMenuItems,
  produtosUSMenuItems,
  contatoMenuItems,
  contatoESMenuItems,
  contatoUSMenuItems,
  institucionalESMenuItems,
  institucionalUSMenuItems,
  institucionalMenuItems
} from './Dropdown/MenuItems';

function Navbar() {
  const navigate = useNavigate();
  const userLanguage = navigator.language !== "pt-BR" && navigator.language !== "en-US" && navigator.language !== "es-ES" ? "en-US" : navigator.language;
  const [language, setLanguage] = useState(userLanguage);
  const [click, setClick] = useState(false);
  const [mobileMode, setMobileMode] = useState(false);
  const [dropdownInstitucional, setDropdownInstitucional] = useState(false);
  const [dropdownInfraestrutura, setDropdownInfraestrutura] = useState(false);
  const [dropdownQualidade, setDropdownQualidade] = useState(false);
  const [dropdownLogistica, setDropdownLogistica] = useState(false);
  const [dropdownProdutos, setDropdownProdutos] = useState(false);
  const [dropdownContato, setDropdownContato] = useState(false);

  useEffect(() => {
    if (window.location.pathname === '/'
      || window.location.pathname === '/en-US'
      || window.location.pathname === '/es-ES') {
      switch (language) {
        case "pt-BR":
          navigate("/");
          break;
        case "en-US":
          navigate("/en-US");
          break;
        case "es-ES":
          navigate("/es-ES");
          break;
        default:
          navigate("/en-US")
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectLanguage(lang: string) {
    setLanguage(lang);
    if (lang !== "pt-BR") {
      navigate(lang);
    } else {
      navigate("/");
    }
  }

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = (item: string) => {

    if (item === "institucional") {
      if (window.innerWidth < 960) {
        setDropdownInstitucional(false);
      } else {
        setDropdownInstitucional(true);
      }
    }

    if (item === "infraestrutura") {
      if (window.innerWidth < 960) {
        setDropdownInfraestrutura(false);
      } else {
        setDropdownInfraestrutura(true);
      }
    }

    if (item === "qualidade") {
      if (window.innerWidth < 960) {
        setDropdownQualidade(false);
      } else {
        setDropdownQualidade(true);
      }
    }

    if (item === "logistica") {
      if (window.innerWidth < 960) {
        setDropdownLogistica(false);
      } else {
        setDropdownLogistica(true);
      }
    }

    if (item === "produtos") {
      if (window.innerWidth < 960) {
        setDropdownProdutos(false);
      } else {
        setDropdownProdutos(true);
      }
    }

    if (item === "contato") {
      if (window.innerWidth < 960) {
        setDropdownContato(false);
      } else {
        setDropdownContato(true);
      }
    }
  };

  const onMouseLeave = (item: string) => {

    if (item === "institucional") {
      if (window.innerWidth < 960) {
        setDropdownInstitucional(false);
      } else {
        setDropdownInstitucional(false);
      }
    }

    if (item === "infraestrutura") {
      if (window.innerWidth < 960) {
        setDropdownInfraestrutura(false);
      } else {
        setDropdownInfraestrutura(false);
      }
    }

    if (item === "qualidade") {
      if (window.innerWidth < 960) {
        setDropdownQualidade(false);
      } else {
        setDropdownQualidade(false);
      }
    }

    if (item === "logistica") {
      if (window.innerWidth < 960) {
        setDropdownLogistica(false);
      } else {
        setDropdownLogistica(false);
      }
    }

    if (item === "produtos") {
      if (window.innerWidth < 960) {
        setDropdownProdutos(false);
      } else {
        setDropdownProdutos(false);
      }
    }

    if (item === "contato") {
      if (window.innerWidth < 960) {
        setDropdownContato(false);
      } else {
        setDropdownContato(false);
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth < 960) {
      setMobileMode(true)
    } else {
      setMobileMode(false);
    }
  }, [mobileMode]);

  return (
    <Container>
      <Section>
        <Link
          to={`/${language === "pt-BR" ? "" : language}`}
          onClick={closeMobileMenu}
        >
          <img src={imgLogo} alt="Logo Zanaflex" />
        </Link>
        {mobileMode === false && (
          <SelectLanguage className={mobileMode ? 'language-active' : 'language-disabled'}>
            <div>
              <p className="lang">{language}</p>
            </div>
            <div className="languages-images">
              <img src={BRLogo} alt="Logo BR" onClick={() => handleSelectLanguage("pt-BR")} />
              <img src={ESLogo} alt="Logo ES" onClick={() => handleSelectLanguage("es-ES")} />
              <img src={USLogo} alt="Logo US" onClick={() => handleSelectLanguage("en-US")} />
            </div>
          </SelectLanguage>
        )}
      </Section>
      <Nav>
        {mobileMode === true && (
          <SelectLanguage className={mobileMode ? 'language-active' : 'language-disabled'}>
            <div>
              <p className="lang">{language}</p>
            </div>
            <div className="languages-images-mobile">
              <img src={BRLogo} alt="Logo BR" onClick={() => handleSelectLanguage("pt-BR")} />
              <img src={ESLogo} alt="Logo ES" onClick={() => handleSelectLanguage("es-ES")} />
              <img src={USLogo} alt="Logo US" onClick={() => handleSelectLanguage("en-US")} />
            </div>
          </SelectLanguage>
        )}
        <MenuIcon onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </MenuIcon>
        <NavMenu className={click ? 'nav-menu active' : 'nav-menu'}>
          <NavItem>
            <Link to={`/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </NavItem>


          <NavItem onMouseEnter={() => onMouseEnter("institucional")}
            onMouseLeave={() => onMouseLeave("institucional")}>
            <Link to={`/institucional/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              {language === "es-ES" ? "Institucional" :
                language === "en-US" ? "Institutional" : "Institucional"} <i className='fas fa-caret-down' />
            </Link>
            {dropdownInstitucional && <Dropdown menuItems={
              language === "es-ES" ? institucionalESMenuItems :
                language === "en-US" ? institucionalUSMenuItems : institucionalMenuItems} />}
          </NavItem>


          <NavItem onMouseEnter={() => onMouseEnter("infraestrutura")}
            onMouseLeave={() => onMouseLeave("infraestrutura")}>
            <Link
              to={`/infraestrutura/${language === "pt-BR" ? "" : language}`}
              className='nav-links'
              onClick={closeMobileMenu}>
              {language === "es-ES" ? "Infraestructura" :
                language === "en-US" ? "Infraestructure" : "Infraestrutura"} <i className='fas fa-caret-down' />
            </Link>
            {dropdownInfraestrutura && <Dropdown menuItems={
              language === "es-ES" ? infraestruturaESMenuItems :
                language === "en-US" ? infraestruturaUSMenuItems : infraestruturaMenuItems} />}
          </NavItem>
          <NavItem>
            <Link to={`/dt/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              D&T
            </Link>
          </NavItem>
          <NavItem onMouseEnter={() => onMouseEnter("qualidade")}
            onMouseLeave={() => onMouseLeave("qualidade")}>
            <Link to={`/qualidade/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              {language === "es-ES" ? "Calidad & Medio Ambiente" :
                language === "en-US" ? "Quality and Environment" : "Qualidade & Meio Ambiente"} <i className='fas fa-caret-down' />
            </Link>
            {dropdownQualidade && <Dropdown menuItems={
              language === "es-ES" ? qualidadeESMenuItems :
                language === "en-US" ? qualidadeUSMenuItems : qualidadeMenuItems} />}
          </NavItem>
          <NavItem onMouseEnter={() => onMouseEnter("logistica")}
            onMouseLeave={() => onMouseLeave("logistica")}>
            <Link to={`/logistica/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              {language === "es-ES" ? "Logística" :
                language === "en-US" ? "Logistics" : "Logística"} <i className='fas fa-caret-down' />
            </Link>
            {dropdownLogistica && <Dropdown menuItems={
              language === "es-ES" ? logisticaESMenuItems :
                language === "en-US" ? logisticaUSMenuItems : logisticaMenuItems} />}
          </NavItem>
          <NavItem onMouseEnter={() => onMouseEnter("produtos")}
            onMouseLeave={() => onMouseLeave("produtos")}>
            <Link to={`/produtos/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              {language === "es-ES" ? "Producto" :
                language === "en-US" ? "Product" : "Produtos"} <i className='fas fa-caret-down' />
            </Link>
            {dropdownProdutos && <Dropdown menuItems={
              language === "es-ES" ? produtosESMenuItems :
                language === "en-US" ? produtosUSMenuItems : produtosMenuItems} />}
          </NavItem>
          <NavItem onMouseEnter={() => onMouseEnter("contato")}
            onMouseLeave={() => onMouseLeave("contato")}>
            <Link to={`/contato/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              {language === "es-ES" ? "Contacto" :
                language === "en-US" ? "Contact" : "Contato"} <i className='fas fa-caret-down' />
            </Link>
            {dropdownContato && <Dropdown menuItems={
              language === "es-ES" ? contatoESMenuItems :
                language === "en-US" ? contatoUSMenuItems : contatoMenuItems} />}
          </NavItem>
          <NavItem>
            <Link to={`/links/${language === "pt-BR" ? "" : language}`} className='nav-links' onClick={closeMobileMenu}>
              {language === "es-ES" ? "Enlaces" : "Links"}
            </Link>
          </NavItem>
        </NavMenu>
      </Nav>
    </Container>
  );
}

export default Navbar;